import React, { useEffect, useState } from 'react';
import LandingPage from './components/LandingPage';

function App() {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  return (
    <div className="App">
     
      <LandingPage />
    </div>
  );
}

export default App;